import {
  ChakraProvider,
  extendTheme,
  Heading,
  HeadingProps,
  Link,
  LinkProps,
  ListItem,
  ListItemProps,
  ListProps,
  OrderedList,
  Text,
  TextProps,
  UnorderedList,
} from "@chakra-ui/react"
import type { AppProps } from "next/app"
import { fonts } from "theme/fonts"
import { MDXProvider } from "@mdx-js/react"

export default function App({ Component, pageProps }: AppProps) {
  const theme = extendTheme({
    fonts: {
      heading: "var(--font-lexend)",
      body: "var(--font-lexend)",
    },
    colors: {
      brand: {
        gray: "#424242",
        black: "#222222",
        primary: "#0BB5A8",
        secondary: "#FFB300",
        light: "#70F2AB",
        dark: "#0A9F94",
        alpha: "rgba(11, 181, 168, 0.9)",
      },
    },
    styles: {
      global: {
        body: {
          color: "brand.primary",
          bg: "white",
          lineHeight: "base",
          scrollbarColor: `#0BB5A8 transparent`,
          "&::-webkit-scrollbar": {
            width: "6px",
          },
          "&::-webkit-scrollbar-track": {
            width: "6px",
            backgroundColor: "#C5E1F2",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#0BB5A8",
            borderRadius: "6px",
          },
        },
      },
    },
  })

  const components = {
    a: (props: LinkProps) => (
      <Link
        color={"brand.primary"}
        textDecoration={"underline"}
        {...props}
      />
    ),
    h1: (props: HeadingProps) => (
      <Heading
        as={"h1"}
        my={4}
        {...props}
      />
    ),
    h2: (props: HeadingProps) => (
      <Heading
        as={"h2"}
        my={4}
        {...props}
      />
    ),
    h3: (props: HeadingProps) => (
      <Heading
        as={"h3"}
        my={4}
        {...props}
      />
    ),
    p: (props: TextProps) => (
      <Text
        mt={4}
        {...props}
      />
    ),
    ul: (props: ListProps) => (
      <UnorderedList
        ms={8}
        {...props}
      />
    ),
    ol: (props: ListProps) => (
      <OrderedList
        ms={8}
        {...props}
      />
    ),
    li: (props: ListItemProps) => <ListItem {...props} />,
  }

  return (
    <>
      <style
        jsx
        global
      >
        {`
          :root {
            --font-lexend: ${fonts.lexend.style.fontFamily};
          }
        `}
      </style>
      <ChakraProvider theme={theme}>
        <MDXProvider components={components}>
          <Component {...pageProps} />
        </MDXProvider>
      </ChakraProvider>
    </>
  )
}
